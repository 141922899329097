<template>
  <div class="course-root">
      <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.course-root {
    min-height: 100vmin;
}
</style>